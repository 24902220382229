import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePaymentCategoriesList() {
  // Use toast
  const toast = useToast()

  const refPaymentCategoryListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'category', label: 'Category', sortable: true },
    { key: 'description', sortable: false },
    { key: 'by', sortable: false },
    { key: '_createdAt', label: 'created', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalPaymentCategories = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('category')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refPaymentCategoryListTable.value ? refPaymentCategoryListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPaymentCategories.value,
    }
  })

  const getPaymentCategories = computed(() => {
    const response =  store.getters['account-payment/getPaymentCategories']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    })

    const { categories, total } = response
    totalPaymentCategories.value = total

    return categories
  })

  const refetchData = () => {
    refPaymentCategoryListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchPaymentCategories = (ctx, callback) => {
    store
      .dispatch('account-payment/fetchPaymentCategories')
      .then(() => {
        // const { PaymentCategories, total } = response.data

        // callback(PaymentCategories)
        
        // totalPaymentCategories.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching PaymentCategories' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const blankPaymentCategoryData = {
    category: '',
    description: '',
  }

  const paymentCategoryData = ref(JSON.parse(JSON.stringify(blankPaymentCategoryData)))

  const clearPaymentCategoryData = () => {
    paymentCategoryData.value = JSON.parse(JSON.stringify(blankPaymentCategoryData))
  }
  const editPaymentCategory = (paymentCategoryEvent) => {
    
    paymentCategoryData.value = JSON.parse(JSON.stringify(Object.assign(paymentCategoryEvent)))
    // const { paymentCategoryData } = data
    // emit('update:is-add-new-paymentCategory-sidebar-active', true)
    isAddNewPaymentCategorySidebarActive.value = true
    // console.log(paymentCategoryEvent)

    
    roleEvent.categoryName = roleEvent.category

    paymentCategoryData.value = grabDataFromEditPaymentCategory(paymentCategoryEvent)
  }

  const grabDataFromEditPaymentCategory = paymentCategoryData => {

    const {
      id,
      category,
      categoryName,
      description,
    } = paymentCategoryData

    return {
      id,
      category,
      categoryName,
      description,
    }
  }

  // ------------------------------------------------
  // addPaymentCategory
  // ------------------------------------------------
  const addPaymentCategory = eventData => {
    store.dispatch('account-payment/addPaymentCategory', { paymentCategory: eventData }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchPaymentCategories()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      //
      isAddNewPaymentCategorySidebarActive.value = false

    }).catch(error=> {

      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
    })
  }

  // ------------------------------------------------
  // updatePaymentCategory
  // ------------------------------------------------
  const updatePaymentCategory = eventData => {
    
    store.dispatch('account-payment/updatePaymentCategory', { category: eventData.categoryName, paymentCategory: eventData }).then(response => {
      
      fetchPaymentCategories()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      isAddNewPaymentCategorySidebarActive.value = false
    }).catch(error=> {

      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

    })
  }

  const delPaymentCategory = eventData => {
    store.dispatch('account-payment/delPaymentCategory', { paymentCategory: eventData }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchPaymentCategories()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }


  const isAddNewPaymentCategorySidebarActive = ref(false)


  return {
    // onSubmit,
    getPaymentCategories,
    fetchPaymentCategories,
    tableColumns,
    perPage,
    currentPage,
    totalPaymentCategories,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPaymentCategoryListTable,

    refetchData,
    isAddNewPaymentCategorySidebarActive,
    addPaymentCategory,
    updatePaymentCategory,
    delPaymentCategory,
    clearPaymentCategoryData,
    editPaymentCategory,
    paymentCategoryData
  }
}
