import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function usePaymentCategoryListAddNew(props, clearForm, emit) {
  // ------------------------------------------------
  // paymentCategoryLocal
  // ------------------------------------------------
  const paymentCategoryLocal = ref(JSON.parse(JSON.stringify(props.paymentCategoryData.value)))
  const resetPaymentCategoryLocal = () => {
    paymentCategoryLocal.value = JSON.parse(JSON.stringify(props.paymentCategoryData.value))
  }
  watch(props.paymentCategoryData, () => {
    resetPaymentCategoryLocal()
  })

  // ------------------------------------------------
  // isAddNewPaymentCategorySidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isAddNewPaymentCategorySidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
//   const calendarOptions = computed(() => store.state.calendar.calendarOptions)

  const onSubmit = () => {
    const paymentCategoryData = JSON.parse(JSON.stringify(paymentCategoryLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.paymentCategoryData.value.id) emit('update-payment-category', paymentCategoryData.value)
    else emit('add-payment-category', paymentCategoryData.value)

    // Close sidebar
    // emit('update:is-add-new-payment-category-sidebar-active', false)
  }


  const serviceOptions = [
    { label: 'First', value: 'first' },
    { label: 'Second', value: 'second' },
    { label: 'One', value: 'one' },
    { label: 'Third', value: 'third' },
    { label: 'Fourth', value: 'fourth' },
    { label: 'Fifth', value: 'fifth' },
  ]

//   const paymentCategorysOptions = computed(() => store.state.calendar.paymentCategorys)

  /* eslint-enable global-require */

  return {
    paymentCategoryLocal,
    resetPaymentCategoryLocal,
    // calendarOptions,

    serviceOptions,
    onSubmit,
  }
}
