<template>

    <!-- Table Container Card -->
    <b-card
      no-body
    >
    <payment-category-list-add-new
      v-model="isAddNewPaymentCategorySidebarActive"
      :payment-category-data="paymentCategoryData"
      :clear-payment-category-data="clearPaymentCategoryData"
      @add-payment-category="addPaymentCategory"
      @update-payment-category="updatePaymentCategory"
    />

      <div class="m-2">
  
        <!-- Table Top -->
        <b-row>
  
          <!-- Per Page -->
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
                variant="primary"
                @click="isAddNewPaymentCategorySidebarActive = true"
                class="mr-1"
            >
                Add Category
            </b-button>

            <!-- <b-button
              variant="outline-primary"
              class="mr-1"
              @click="genPaymentCategoryReport"
            >
              Export to *.Excel
            </b-button> -->
          </b-col>
  
          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              
            </div>
          </b-col>
        </b-row>
  
      </div>
  
      <b-table
        ref="refPaymentCategoryListTable"
        :items="getPaymentCategories"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
  
        <!-- Column: Id -->
        <template #cell(id)="data">
          <!-- <b-link
            :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
            class="font-weight-bold"
          > -->
            <!-- #{{ data.value }} -->
            {{ data.index+1 }}
          <!-- </b-link> -->
        </template>


        <template #cell(category)="data">

            <b-link
                :to="{ name: 'accounts-payment-list', params: { search: data.item.category }}"
                class="font-weight-bold"
            >
            {{ data.item.category }}
          </b-link>
        </template>

        <template #cell(count)="data">
          <b-link
            class="font-weight-bold"
            :to="{ name: 'app-payment-category-list', params: {  type: data.item.type, date: new Date(data.item.paymentCategoryDate).toGMTString().substring(5,16), service: data.item.service, } }"
          >
            {{ data.item.count  }} payment Categories
          </b-link>
        </template>
  
        <!-- Column: Actions -->
        <template #cell(actions)="data">
  
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class=""
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
            
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'accounts-payment-list', params: { add: data.item.category }}">
                <feather-icon icon="PlusIcon" />
                <span class="align-middle ml-50">Add Pay</span>
                </b-dropdown-item>
              <b-dropdown-item @click="editPaymentCategory(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="delPaymentCategory(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
  
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
  
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries </span>
            
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
  
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPaymentCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
  
          </b-col>
  
        </b-row>
      </div>
    </b-card>
  
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BFormTextarea, BFormDatepicker, BFormTimepicker, BForm, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BAlert,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { ref, onUnmounted, computed } from '@vue/composition-api'
  import store from '@/store'
  import usePaymentCategory from './usePaymentCategory'
  import flatPickr from 'vue-flatpickr-component'
  
  import paymentStoreModule from '../paymentStoreModule'
  import router from '@/router'
  import PaymentCategoryListAddNew from '../payment-category-add-new/PaymentCategoryListAddNew.vue'
  import Ripple from 'vue-ripple-directive'
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BFormTextarea,
      BFormDatepicker,
      BFormTimepicker,
      BForm,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BFormGroup,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BTooltip,
      BAlert,
      flatPickr,
      vSelect,
      PaymentCategoryListAddNew,
    },
    directives: {
        Ripple,
    },
    data() {
      return {
        router
      }
    },
    setup() {
  
      const ACCOUNT_PAYMENT_STORE_MODULE_NAME = 'account-payment'
  
      // Register module
      if (!store.hasModule(ACCOUNT_PAYMENT_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_PAYMENT_STORE_MODULE_NAME, paymentStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ACCOUNT_PAYMENT_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_PAYMENT_STORE_MODULE_NAME)
      })

      const serviceOptions = [
        { label: 'First', value: 'first' },
        { label: 'Second', value: 'second' },
        { label: 'One', value: 'one' },
        { label: 'Third', value: 'third' },
        { label: 'Fourth', value: 'fourth' },
        { label: 'Fifth', value: 'fifth' },
      ]

  
      const rangeOptions = [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        // { label: 'This Week', value: 'this week' },
        // { label: 'Previous Week', value: 'previous week' },
        { label: 'This Month', value: 'this month' },
        { label: 'Previous Month', value: 'previous month' },
        { label: 'This Year', value: 'this year' },
        { label: 'Previous Year', value: 'previous year' }
      ]
  
      const {
        fetchPaymentCategories,
        approvePaymentCategories,
        getPaymentCategories,
        tableColumns,
        perPage,
        currentPage,
        totalPaymentCategories,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPaymentCategoryListTable,
  
        refetchData,
        isAddNewPaymentCategorySidebarActive,
        addPaymentCategory,
        updatePaymentCategory,
        delPaymentCategory,
        clearPaymentCategoryData,
        editPaymentCategory,
        paymentCategoryData,
        
      } = usePaymentCategory()
  
      fetchPaymentCategories()

      
      
      return {
        approvePaymentCategories,
        getPaymentCategories,
        tableColumns,
        perPage,
        currentPage,
        totalPaymentCategories,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPaymentCategoryListTable,
        serviceOptions,
 
        refetchData,
        rangeOptions,
        isAddNewPaymentCategorySidebarActive,
        addPaymentCategory,
        updatePaymentCategory,
        delPaymentCategory,
        clearPaymentCategoryData,
        editPaymentCategory,
        paymentCategoryData,
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .invoice-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  