<template>
    <b-sidebar
      id="add-new-payment-category-sidebar"
      :visible="isAddNewPaymentCategorySidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-add-new-payment-category-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ paymentCategoryLocal.id ? 'Update': 'Add' }} Payment Category
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            
            <!-- Member -->
            <validation-provider
              #default="validationContext"
              name="category"
              rules="required"
            >
              <b-form-group
                  label="Category"
                  label-for="category"
                  :state="getValidationState(validationContext)"
                >
                    <b-form-input
                    id="category"
                    v-model="paymentCategoryLocal.category"
                    trim
                    placeholder="Category"
                    />
                  
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Note -->
            <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="paymentCategoryLocal.description"
                  trim
                  placeholder="Description"
                >
                </b-form-textarea>
            </b-form-group>
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormDatepicker, BFormTextarea, BFormInvalidFeedback, BButton,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref,toRefs } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import usePaymentCategoryListAddNew from './usePaymentCategoryListAddNew'
  import flatPickr from 'vue-flatpickr-component'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormDatepicker,
      BFormTextarea,
      BFormInvalidFeedback,
      BButton,
      vSelect,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      flatPickr
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewPaymentCategorySidebarActive',
      event: 'update:is-add-new-payment-category-sidebar-active',
    },
    props: {
      isAddNewPaymentCategorySidebarActive: {
        type: Boolean,
        required: true,
      },
      paymentCategoryData: {
        type: Object
      },
      clearPaymentCategoryData: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
      }
    },
    setup(props, { emit }) {
      
      const clearFormData = ref(null)

      const { 
        paymentCategoryLocal,
        resetPaymentCategoryLocal,
        onSubmit,
        serviceOptions
      
      } = usePaymentCategoryListAddNew(toRefs(props), clearFormData, emit)
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
      } = formValidation(resetPaymentCategoryLocal, props.clearPaymentCategoryData)

      clearFormData.value = clearForm
  
      return {
        paymentCategoryLocal,
        onSubmit,
        serviceOptions,
        resetForm,
        refFormObserver,
        getValidationState
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  #add-new-payment-category-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  